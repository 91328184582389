@import url('https://fonts.googleapis.com/css?family=Raleway');

.parent {
	display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.fullReview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.5rem;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.6rem;
}

.thumbs {
  align-self: center;
}

.slick-dots{
  text-align: left;
}

.slick-dots li{
  margin: 0px;
}
