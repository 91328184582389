@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Raleway', sans-serif;
}
div {
  color: #555555;
}
h1 {
  color: #555555;
  font-size: 3rem;
  font-weight: 800; /* extra bold */
  margin: 0;
}
h2 {
  color: #555555;
  font-size: 1.7rem;
  font-weight: 700; /* bold */
  margin: 0;
}
h3 {
  color: #555555;
  font-size: 1.3rem;
  font-weight: 700; /* bold */
  margin: 0;
}
h4 {
  color: #555555;
  font-size: 1rem;
  font-weight: 700; /* bold */
  margin: 0;
}
h5 {
  color: #555555;
  font-size: 1rem;
  font-weight: 400; /* normal */
  margin: 0;
}
h6 {
  color: #555555;
  font-size: 0.8rem;
  font-weight: 700; /* bold */
  margin: 0;
}
p {
  color: #555555;
  font-size: 1rem;
  font-weight: 400; /* normal */
  margin: 0;
}
b {
  color: #555555;
  font-size: 1rem;
  font-weight: 700; /* bold */
  margin: 0;
}
