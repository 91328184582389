#parent {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#reviewQuickView {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-bottom: 0.5rem;
	height: 100%;
}

#criteriaQuickReview {
	padding-top:0.5rem;
	padding-bottom: 0.5rem;
}

#readMore {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
}